<script setup lang="ts">
const props = defineProps<{
    target: Date;
}>();

const intervalId = ref<number | null>(null);
const remainingDays = ref<number>(0);
const remainingHours = ref<number>(0);
const remainingMinutes = ref<number>(0);
const remainingSeconds = ref<number>(0);

function setDifference() {
    const now = Date.now();

    const diff = props.target.getTime() - now;

    if (diff <= 0 && intervalId.value) {
        clearInterval(intervalId.value);
        return;
    }

    remainingDays.value = Math.floor(diff / (1000 * 60 * 60 * 24));
    remainingHours.value = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    remainingMinutes.value = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    remainingSeconds.value = Math.floor((diff % (1000 * 60)) / 1000);
}

onMounted(() => {

    const startingDate = Date.now();

    if (startingDate > props.target.getTime()) {
        return;
    }


    setDifference()
    intervalId.value = window.setInterval(() => {

        setDifference()

    }, 1000);

})

onUnmounted(() => {

    if (intervalId.value) {
        clearInterval(intervalId.value);
    }

})

</script>

<template>
    
    <slot :days="remainingDays.toString().padStart(2, '0')" :hours="remainingHours.toString().padStart(2, '0')"
        :minutes="remainingMinutes.toString().padStart(2, '0')" :seconds="remainingSeconds.toString().padStart(2, '0')">
    </slot>
</template>